// &:before { @include before(100px, 100px); background: url(/i/design/) no-repeat center; top: 0; left: 0; } 

// -----------------------------------//
// FONTS
// -----------------------------------//

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

// -----------------------------------//
// VARIABLES
// -----------------------------------//

$link-color: deepskyblue;

$h1-color: #65BD58;
$h2-color: #2B616C;
$h3-bg-color: #65BD58;
$h4-bg-color: #2B616C;

// -----------------------------------//
// BODY
// -----------------------------------//

body {
	text-align: center;
	padding: 0;
	margin: 0;
	position: relative;
	overflow-x: hidden;
	font-family: 'Poppins', sans-serif;
}

.body_57553 {}

// -----------------------------------//
// GLOBAL
// -----------------------------------//

.body_57553 * {
	box-sizing: border-box;
}

body a {
	color: $link-color;
	text-decoration: none;
	transition: .5s;
}

body a:hover,
body a:focus {
	text-decoration: none;
}

a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

h1 {
	font-size: 52px;
	margin: 0 0 20px 0;
	color: $h1-color;
	line-height: 1.2;
}

h2 {
	font-size: 35px;
	margin: 25px 0 20px 0;
	color: $h2-color;
	line-height: 1.3;
}

h3 {
	font-size: 20px;
	margin: 25px 0 10px 0;
	padding: 5px 10px 3px 10px;
	background: $h3-bg-color;
	color: #fff;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
	background-color: $h4-bg-color;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

hr {
	height: 1px;
	border-width: 0;
	color: #f2f2f2;
	background-color: #f2f2f2;
}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
	max-width: 2000px;
	overflow: hidden;
}

.wrapper {
	// width: 990px;
	width: 84%;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;

	@media only screen and (max-width: 1170px) {
		width: 93%;
	}
}

.wrapper-inside {
	width: 1500px;
	max-width: 90%;
	margin: 0 auto;
	@extend .clearfix;
	position: relative;
}

// fix home page wrapper called in t.asp
.body_57553 .wrapper-inside {
	width: 100%;
	max-width: 100%;
	padding: 0;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	padding: 0;
	position: relative;

	&:before {
		position: absolute;
		content: "";
		background: url(/i/design/banner.png);
		height: 79px;
		width: 1920px;
		top: 0;
		left: 0;
		z-index: -1;

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}
}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;
}

header h1 {
	margin: 0;
	background: none;
}

#logo-index {
	margin: 0;
	padding: 0;
	position: relative;
	top: 110px;

	@media only screen and (max-width: 990px) {
		top: 170px;
	}

	a {
		display: flex;
		align-items: center;
		gap: 35px;

		@media only screen and (max-width: 990px) {
			flex-direction: column;
			align-items: center;
			gap: 0;
		}

		img#logo {
			position: relative;
		}
	}
}

// -----------------------------------//
// SOCIAL DOCK
// -----------------------------------//

#social-dock {
	position: absolute;
	right: 130px;
	top: 19px;
	z-index: 99;
	display: flex;
	align-items: center;

	@media only screen and (max-width: 1060px) {
		right: 10px;
	}

	@media only screen and (max-width: 990px) {
		left: 0;
		right: 0;
		top: 100px;
		justify-content: center;
	}

	li {

		// @include box(26px);
		padding: 0;
		background: none;
		margin: 0 10px;

		a {
			display: block;
			@include box(100%);
		}

		#ceop-head {
			@include box(110px, 42px);
			overflow: hidden;
			z-index: 99;
			background: url(/i/design/ceop.png) no-repeat center;
		}

		//	#twitter-head { @include box(26px); overflow: hidden; z-index: 99; background: url(/i/design/tw-ic.png) no-repeat center; } 
		//	#instagram-head { @include box(26px); overflow: hidden; z-index: 99; background: url(/i/design/in-ic.png) no-repeat center; } 
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element {
	opacity: 0;
}

.translate {
	@include box(26px);
	overflow: hidden;
	background: url(/i/design/translate.png) no-repeat center;
	z-index: 99;
}

.goog-te-gadget .goog-te-combo {
	padding: 10px;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

.search-li {
	@include box(26px);
}

a#FS {
	@include box(26px);
	background: url(/i/design/search.png) no-repeat center;
	padding: 0px;
	transition: all .3s ease;
	cursor: pointer;
}

/* Search Style */
#search {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	transition: all 0.5s ease-in-out;
	transform: translate(0px, -100%) scale(0, 0);
	opacity: 0;
	display: none;
}

#search.open {
	transform: translate(0px, 0px) scale(1, 1);
	opacity: 1;
	z-index: 9999999;
	display: block;
}

#search input[type="search"] {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -51px;
	width: 60%;
	margin-left: 20%;
	color: rgb(255, 255, 255);
	background: transparent;
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	font-size: 40px;
	font-weight: 300;
	text-align: center;
	outline: none;
	padding: 10px;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: lightblue;
$search-highlight-color: darkblue;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

#mainholder {
	width: 100%;
	margin: 0px auto;
	padding: 30px 0 100px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
	position: relative;
	z-index: 10;
}

// home page alternate
.body_57553 #mainholder {
	padding: 0;
	margin: 0;
	position: relative;
}


#home-btns {
	position: absolute;
	z-index: 10;
	top: -680px;
	right: 60px;

	@media only screen and (max-width: 1070px) {
		top: -590px;
	}

	@media only screen and (max-width: 990px) {
		position: relative;
		top: auto;
		right: auto;
	}

	ul {
		display: flex;
		flex-direction: column;
		gap: 10px;

		@media only screen and (max-width: 990px) {
			gap: 0;
			flex-wrap: wrap;
			flex-direction: row;
		}

		li {
			position: relative;
			height: 170px;
			width: 170px;
			border-radius: 50%;
			box-shadow: inset 0px 0px 20px 10px rgba(#fff, 0.35);
			mix-blend-mode: color-dodge;
			padding: 0;
			transition: 0.4s;
			margin: 0;

			@media only screen and (max-width: 990px) {
				border-radius: 0;

				&:nth-of-type(1),
				&:nth-of-type(2) {
					width: calc(100%/2);

					@media only screen and (max-width: 500px) {
						width: 100%;
					}
				}

				&:nth-of-type(3) {
					width: 100%;
				}
			}

			@media only screen and (max-width: 500px) {
				height: 140px;
			}

			&:hover {
				filter: brightness(110%) drop-shadow(0px 0px 10px #fff);
				box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.5);

				&:before {
					rotate: 2deg;
				}

				a {

					span {
						translate: 0 -4px;
					}
				}
			}

			&:before {
				position: absolute;
				content: "";
				background: url(/i/design/leaf-accent.png) center center;
				width: 64px;
				height: 53px;
				bottom: 10px;
				left: 50%;
				transform: translateX(-50%);
				transition: 0.5s;

				@media only screen and (max-width: 990px) {
					display: none;
				}
			}

			&:nth-of-type(1) {
				background: #4FC0D0;
				box-shadow: inset 0px 0px 20px 10px #98fafa;

				&:before {
					filter: invert(29%) sepia(54%) saturate(519%) hue-rotate(146deg) brightness(92%) contrast(85%);
				}
			}

			&:nth-of-type(2) {
				background: #285D6B;
				box-shadow: inset 0px 0px 20px 10px #3D8DA3;

				&:before {
					filter: invert(69%) sepia(90%) saturate(318%) hue-rotate(142deg) brightness(87%) contrast(85%);
				}
			}

			&:nth-of-type(3) {
				background: #67B73A;
				box-shadow: inset 0px 0px 20px 10px #aeff77;

				&:before {
					filter: invert(89%) sepia(52%) saturate(621%) hue-rotate(357deg) brightness(96%) contrast(86%);
				}
			}

			a {
				position: relative;
				display: flex;
				height: 100%;
				width: 100%;
				border-radius: 50%;
				align-items: center;
				justify-content: center;

				span {
					position: absolute;
					color: #fff;
					text-transform: uppercase;
					font-size: 17px;
					line-height: 22px;
					text-align: center;
					transition: 0.3s;
					font-weight: 500;

					@media only screen and (max-width: 990px) {
						font-size: 25px;
						line-height: normal;

						br {
							display: none;
						}
					}
				}
			}
		}
	}
}

// -----------------------------------//
// WELCOME SEC
// -----------------------------------//

#welcome-sec {
	padding: 100px 0;
	background: #fff;
	position: relative;

	h2 {
		span {
			display: block;
		}
	}

	p {}

	.read-more {
		display: block;
	}
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column {
	width: 100%;
	max-width: 1300px;
	margin: 0 auto 50px;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	background: rgba(255, 255, 255, .9);
	border-radius: 20px;
	@extend .clearfix;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 2
.Content2Column {
	width: calc(92% - 350px);
	margin: 0 0 50px 8%;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	display: inline-block;
	float: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;
	border-radius: 20px;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;
	border-radius: 20px;

	img {
		max-width: 100%;
		height: auto;
	}
}

#mainholder {

	tr,
	td {
		border: 1px solid #BFBFBF;
	}

	td {
		padding: 5px;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto 50px;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 300px;
	margin: 0 20px 0 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: right;
	box-sizing: border-box;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 0;
	padding: 0;
	text-align: center;
}

// List
ul.SideList {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 0px;
	color: #000;
	transition: .5s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	transition: .5s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
	padding-left: 40px !important;
}

// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	text-align: center;
	font-size: 20px;
	line-height: 130%;
	transition: .5s;

	// Span = number
	span {
		display: block;
		transition: .5s;
	}
}

ul.SideList p.title {
	float: right;
	width: 100%;
	font-size: 16px;
	transition: .5s;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 35px;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 18px;
	padding: 0px;
	transition: .5s;

	&:hover {
		transition: .5s;
		text-decoration: none;
	}
}

// -----------------------------------//
// DIARY
// -----------------------------------//

//Diary
#SideHeadingDiary {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// NEWS
// -----------------------------------//

//News
#SideHeadingNews {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// RELATED PAGES
// -----------------------------------//

#SideHeadingPages {
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 5%;
	background-color: #468697;
	border-radius: 20px;

	h2 {
		display: block;
		text-align: center;
		font-size: 28px;
		padding: 20px 0;
		margin: 0;
		color: #fff;
	}

	ul.SideList {
		padding: 0 0 30px 0;

		li {
			a {
				padding: 7px 20px;
				font-size: 18px;
				color: #fff;

				&:hover {
					background-color: rgba(255, 255, 255, .1);
				}
			}
		}
	}
}

// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}

// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	// overflow: hidden;
	background: #fff;
	padding: 10px 0 0 0;
	height: 85px;
	position: relative;
	z-index: 10;

	&:before {
		position: absolute;
		content: "";
		background: url(/i/design/awards-banner.png);
		width: 1920px;
		height: 5px;
		left: 0;
		top: -5px;
		z-index: 10;
	}

	a {
		position: absolute;
		top: -33px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 10;

		@media only screen and (max-width: 1290px) {
			top: -23px;
		}

		@media only screen and (max-width: 1070px) {
			display: none;
		}
	}

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;
			max-height: 70px;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 10px;
				display: inline-block;

				a {
					position: relative;
					top: auto;
					left: auto;
					transform: none;
					z-index: 10;
				}

				img {

					transform: none !important;
					max-width: none !important;
					margin: 0 !important;
				}
			}
		}
	}
}

// -----------------------------------//
// SEASONAL FX
// -----------------------------------//

.seasonaleffect {
	z-index: 999 !important;
}

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	margin: 0 auto;
	clear: both;
	background: none;
	position: absolute;
	bottom: 115px;
	z-index: 10;
	width: 100%;

	@media only screen and (max-width: 1070px) {
		position: relative;
		bottom: auto;
		padding: 40px 0;
		background: #27794B;
	}

	.wrapper {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		@media only screen and (max-width: 1070px) {
			flex-direction: column-reverse;
			align-items: center;
		}

		p,
		a {
			margin: 0 !important;
		}

		p {
			color: #fff;
		}

		a {
			color: #E6E44A;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.left {
		text-align: left;
		display: flex;
		font-size: 11px;
		gap: 15px;

		@media only screen and (max-width: 1070px) {
			text-align: center;
			flex-direction: column;
			gap: 0;
			margin: 15px 0 0 0;
		}

		#copyright {}

		#credit {
			a {
				&:nth-of-type(1) {
					color: #fff;
				}
			}
		}
	}

	.right {
		margin-left: auto;
		text-align: right;
		font-size: 14px;
		line-height: 19px;
		font-weight: 500;

		@media only screen and (max-width: 1070px) {
			margin-left: 0;
			text-align: center;
		}

		p.address {

			span {
				text-transform: uppercase;
				display: block;

				@media only screen and (max-width: 1070px) {}
			}
		}

		p.telephone {
			span {
				margin: 0 10px;

				@media only screen and (max-width: 1070px) {
					display: none;
				}
			}
		}

		a[href^="mailto:"] {}

		.contact {
			display: flex;
			align-items: center;
			// gap: 15px;

			@media only screen and (max-width: 1070px) {
				text-align: center;
				flex-direction: column;
				margin: 15px 0 0 0;
			}
		}
	}



}


// -----------------------------------//
// TOP BUTTON
// -----------------------------------//

.top-btn {
	position: fixed;
	z-index: 99999;
	bottom: 50px;
	right: 30px;
	display: block;
	width: 45px;
	height: 45px;
	background: rgba(0, 0, 0, .3);
	border-radius: 50%;
	color: #fff;
	font-size: 25px;
	text-align: center;
	opacity: 0;
	transform: scale(0);
	transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

	@media only screen and (max-width: 1199px) {
		display: none;
	}
}

.show-top-btn {
	opacity: 1;
	transform: scale(1);
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #222;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	text-align: center;
}

$blockquote-background-color: #fff;
$blockquote-border-color: #eeeeee;
$blockquote-color: #222;
$blockquote-speech-mark-color: #222;
$blockquote-font-size: 1em;