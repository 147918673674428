// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_57553 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 800px;

  .backstretch {}
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 480px;
  z-index: 1;
}

.backstretch {
  position: relative;

  position: fixed !important;
  height: 100vh !important;

  // for top of page gradient
  &:before {
    @include before(100%, 301px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0px;
    left: 0;
  }

  &:after {
    @include before(100%, 364px);
    background: url(/i/design/gradient-btm.png) repeat-x bottom left;
    bottom: 0px;
    left: 0;
  }

  img {
    top: 0px !important;
    min-height: 100% !important;
    min-width: 100% !important;
    object-fit: cover;
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  // bottom: 100px;
  bottom: 40px;
  margin: 0 auto;
  text-align: left;
  color: #fff;
  font-size: 3.750vw;
  width: 100%;
  font-weight: 700;
  transition: 0.4s;

  &:hover {
    img {
      filter: blur(1px);
    }
  }

  @media only screen and (max-width: 1070px) {
    bottom: 50px;
  }

  @media only screen and (max-width: 990px) {
    left: 0;
    right: 0;
    text-align: center;
    font-size: 37px;

    .wrapper {
      width: 90%;
    }
  }

  br {
    display: none;

    @media only screen and (max-width: 990px) {
      display: block;
    }
  }

  .text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media only screen and (max-width: 990px) {
      position: relative;
      top: auto;
      transform: none;
    }
  }

  img {
    position: relative;
    left: -30px;
    padding: 0 25% 0 0;
    transition: 0.4s;

    @media only screen and (max-width: 990px) {
      display: none;
    }
  }

  span {
    font-size: 2.500vw;
    font-weight: 500;
    font-style: italic;
    // margin: 0 5px 0 0;
    position: relative;
    top: -7px;

    @media only screen and (max-width: 990px) {
      font-size: 37px;
      top: auto;
      margin: 0;
    }

    &:nth-of-type(1) {
      color: #67B73A;
    }

    &:nth-of-type(2) {
      color: #4FC0D0;
    }

    &:nth-of-type(3) {
      color: #E6E44A;
    }
  }
}